import React from "react";
import ReactDOM from "react-dom";
import './index.css'

function Square(props) {
  return (
    <button className={props.isWinner ? "square winner" : "square"} onClick={props.onClick}>
      {props.value}
    </button>
  );
}

class Board extends React.Component {
  renderSquare(i, isWinner) {
    return <Square key={i} value={this.props.squares[i]} isWinner={isWinner} onClick={() => this.props.onClick(i)} />;
  }

  render() {
    const rows = [0, 1, 2];
    const cols = [0, 1, 2];
    const winner = this.props.winner;

    return (
      <div>
        <div className="status">{this.props.status}</div>
        {rows.map((row, _) => {
          return (
            <div key={row} className="board-row">
              {cols.map((col, _) => {
                const loc = row * 3 + col;
                const isWinner = winner && (loc === winner[0] || loc === winner[1] || loc === winner[2]);
                return this.renderSquare(loc, isWinner);
              })}
            </div>
          )
        })}
      </div>
    );
  }
}

class Game extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      history: [{
        stepLoc: null,
        squares: Array(9).fill(null),
      }],
      stepNumber: 0,
      xIsNext: true,
    };
  }

  handleClick(i) {
    const history = this.state.history.slice(0, this.state.stepNumber + 1);
    const current = history[history.length - 1];
    const squares = current.squares.slice();
    if (calculateWinner(squares) || squares[i]) {
      return;
    }
    squares[i] = this.state.xIsNext ? 'X' : 'O';
    this.setState({
      history: history.concat([{stepLoc: i, squares: squares}]),
      stepNumber: history.length,
      xIsNext: !this.state.xIsNext,
    });
  }

  jumpTo(step) {
    this.setState({
      stepNumber: step,
      xIsNext: (step % 2) === 0,
    });
  }

  render() {
    const history = this.state.history;
    const current = history[this.state.stepNumber];
    const squares = current.squares.slice();
    const winner = calculateWinner(squares);
    let status;
    if (winner) {
      status = 'Winner:' + squares[winner[0]];
    } else if (this.state.stepNumber === 9) {
      status = "Draw!"
    } else {
      status = 'Next player:' + (this.state.xIsNext ? 'X' : 'O');
    }

    const moves = history.map((_, move) => {
      const stepLoc = history[move].stepLoc;
      const col = stepLoc % 3 + 1;
      const row = parseInt(stepLoc / 3) + 1;
      const desc = move ? 'Go to move #' + move + ` {${col}, ${row}}` : 'Go to game start';
      const className = move && this.state.stepNumber === move ? "history-selected" : "";  // ?
      return <li key={move}><button className={className} onClick={() => this.jumpTo(move)}>{desc}</button></li>
    });

    return (
      <div className="game">
        <div className="game-board">
          <Board squares={squares} winner={winner} onClick={(i) => this.handleClick(i)}/>
        </div>
        <div className="game-info">
          <div>{status}</div>
          <ol>{moves}</ol>
        </div>
      </div>
    );
  }
}

// ========================================

ReactDOM.render(
  <Game />,
  document.getElementById('root')
);

function calculateWinner(squares) {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6]
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b ,c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return [a, b, c];
    }
  }

  return null;
}
